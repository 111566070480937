
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmColoredUl from '@/components/shared/templates/TmColoredUl.vue'
import TmChip from '@/components/shared/TmChip.vue'

export default defineComponent({
  components: {
    TmModal,
    TmFormLine,
    TmButton,
    TmColoredUl,
    TmChip,
  },
  setup() {
    const creditAmountOptions = [
      '$20.00 + bonus $4',
      '$50.00 + bonus $10',
      '$100.00 + bonus $20',
      '$200.00 + bonus $40',
      '$500.00 + bonus $100',
      '$1000.00 + bonus $200',
      '$2000.00 + bonus $400',
      '$3000.00 + bonus $600',
    ]
    const creditAmount = ref(creditAmountOptions[0])

    return {
      creditAmountOptions,
      creditAmount,
    }
  },
})
